<template>
  <div class="RequeteCreation" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Ajouter une nouvelle requête</h1>
        <div class="form_div">
          <div class="form_content">

            <!-- ERROR MODAL -->

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Une erreur s'est produite">
              <div class="text-center">
                <p>Une erreur s'est produite,
                  veuillez vérifier les données renseignées, actualiser la page ou nous contacter directement
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <b-modal ref="RequeteCreatedModal" id="RequeteCreatedModal" size="lg" hide-footer title="Requête créée">
              <div class="text-center">
                <p><strong>Votre requête a bien été créée ! </strong></p>

                <b-button class="mt-3" block pull variant="outline-dark" @click="$router.push('/requetes')">
                  Retour à la liste des requêtes
                </b-button>
              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#F9BF3B">
            </loading>

            <div class="text-right">
              <small><em> <span style="color: red;">*</span>Champs obligatoires</em></small>
            </div>
            <b-form v-on:submit.prevent="createRequete()">
              <b-form-group id="create-requete-name-group" label="Nom de la requête" label-for="create-requete-name-input" class="mandatory-input">
                <b-form-input
                  v-model="createRequeteName" type="text"
                  maxlength="250"
                  placeholder="Nom"
                  id="create-requete-username-input"
                  @input="$v.createRequeteName.$touch()"
                  :state="$v.createRequeteName.$dirty ? !$v.createRequeteName.$error : null">
                </b-form-input>
                <div class="form-error" v-if="$v.createRequeteName.$error">Votre nom doit faire entre 2 et 250 caractères</div>
              </b-form-group>

              <b-form-group id="create-requete-inclusion-group" label="Requête" label-for="create-requete-inclusion-input" class="mandatory-input">
                <b-form-textarea
                  v-model="createRequeteInclusion" type="text"
                  placeholder="Xpath d'inclusion"
                  id="create-requete-username-input"
                  rows="10"
                  @input="$v.createRequeteInclusion.$touch()"
                  :state="$v.createRequeteInclusion.$dirty ? !$v.createRequeteInclusion.$error : null">
                </b-form-textarea>
                <div class="form-error" v-if="$v.createRequeteInclusion.$error">Veuillez renseigner une requête</div>
              </b-form-group>

              <b-form-group id="create-requete-second_part_inclusion-group" label="Seconde requête" label-for="create-requete-second_part_inclusion-input" class="mandatory-input">
                <b-form-textarea
                  v-model="createRequeteSecondPartInclusion" type="text"
                  placeholder="Xpath d'inclusion"
                  id="create-second_part_inclusion-username-input"
                  rows="10"
                  @input="$v.createRequeteSecondPartInclusion.$touch()"
                  :state="$v.createRequeteSecondPartInclusion.$dirty ? !$v.createRequeteSecondPartInclusion.$error : null">
                </b-form-textarea>
                <div class="form-error" v-if="$v.createRequeteSecondPartInclusion.$error">Veuillez renseigner une requête d'au moins 2 caractères</div>
              </b-form-group>

              <b-form-group id="create-requete-exclusion-group" label="Requête d'exclusion" label-for="create-requete-exclusion-input">
                <b-form-textarea
                  v-model="createRequeteExclusion" type="text"
                  placeholder="Xpath d'exclusion"
                  rows="10"
                  id="create-requete-username-input">
                </b-form-textarea>
              </b-form-group>

              <b-button
                pill block type="submit" variant="outline-dark" class="mt-2"
                :disabled="isInvalidRequete">
                  Créer votre requête
              </b-button>
            </b-form>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'
import { APIRequete } from '@/api/APIRequete'
import formTitleComponent from '@/components/formTitleComponent'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

const apiRequete = new APIRequete()

export default {
  name: 'RequeteCreation',
  components: {
    formTitleComponent,
    Loading
  },
  mixins: [
    validationMixin
  ],
  data: function () {
    return {

      createRequeteName: '',
      createRequeteInclusion: '',
      createRequeteSecondPartInclusion: '',
      createRequeteExclusion: '',
      createRequeteInProcess: false,
    }
  },
  computed: {
    token () {
      return this.$store.state.auth.token
    },
    isInvalidRequete () {

      if (this.$v.createRequeteName.$invalid || this.$v.createRequeteInclusion.$invalid || this.$v.createRequeteSecondPartInclusion.$invalid) {
        return true
      }
      return false
    },
    isLoading () {
      if (this.createRequeteInProcess) {
        return true
      }
      return false
    },
  },
  validations: {
    createRequeteName: {
      minLength: minLength(2),
      maxLength: maxLength(250),
      required
    },
    createRequeteInclusion: {
      minLength: minLength(2),
      required
    },
    createRequeteSecondPartInclusion: {
      minLength: minLength(2),
    },
  },

  methods: {
    createRequete () {
      this.createRequeteInProcess = true
      apiRequete.createRequete(
        this.token, this.createRequeteName, this.createRequeteInclusion, this.createRequeteSecondPartInclusion, this.createRequeteExclusion)
      .then(() => {
        this.$refs.RequeteCreatedModal.show()
      })
      .catch((error) => {
        this.$refs.globalAPILoadingErrorModal.show()
        throw new Error('RequeteCreation createRequete API Error : ' + String(error))
      })
      .finally(() => {
        this.createRequeteInProcess = false
      })

    },
  }
}
</script>
